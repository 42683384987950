import { GlobalMessenger as globalMessenger, localStorageHelper as StorageHelper, MasterMessageChannel } from 'o365-modules';

class NotesAppIframe {
    static isOpen = false;
    static #isInternalConstructing: boolean;
    private static _instance?: NotesAppIframe;

    private _initialized = false;
    private _rootEl?: HTMLElement;
    private _destkopContainer?: HTMLElement;
    private _appContainer?: HTMLElement;
    private _messageChannel?: MasterMessageChannel;
    private _globalMessenger?: Function;

    static getInstance() {
        if (!NotesAppIframe._instance) {
            NotesAppIframe.#isInternalConstructing = true;
            NotesAppIframe._instance = new NotesAppIframe();
            NotesAppIframe._instance._initialize();
            NotesAppIframe.#isInternalConstructing = false;
        }
        return NotesAppIframe._instance;
    }

    constructor() {
        if (!NotesAppIframe.#isInternalConstructing) {
            throw new TypeError('NotesAppSingleton is not constructable, use NotesAppSingleton.getInstance()');
        }
    }

    close() {
        if (this._destkopContainer) {
            this._destkopContainer.style.right = '';
        } else if (this._appContainer) {
            this._appContainer.style.right = '';
            this._appContainer.classList.remove('app-container', 'overflow-y-auto');
        }
        document.body.style.overflowY = '';
        this._messageChannel?.close();
        // this._globalMessenger!();
        this._rootEl?.remove();
        NotesAppIframe._instance = undefined;
        NotesAppIframe.isOpen = false;
        StorageHelper.removeItem('o365-notes-app-open', {
            global: true
        });

    }

    private _initialize() {
        if (this._initialized) { return; }
        this._initialized = true;

        NotesAppIframe.isOpen = true;

        StorageHelper.setItem('o365-notes-app-open', 'true', {
            global: true
        });

        this._destkopContainer = document.querySelector('.app-container') as HTMLElement;
        if (this._destkopContainer) {
            this._destkopContainer.style.right = '400px';
        } else {
            this._appContainer = document.querySelectorAll('div[data-v-app]')[0] as HTMLElement;
            if (this._appContainer) {
                this._appContainer.classList.add('app-container', 'overflow-y-auto');
                this._appContainer.style.right = '400px';
            }
        }
        document.body.style.overflowY = 'unset';

        this._messageChannel = new MasterMessageChannel({
            id: 'o365-notes-app-channel',
            connectDelay: 500,
            targetOrigin: 'https://omega.omega365.com',
            functions: {
                'close': () => {
                    this.close();
                },
            }
        });

        this._rootEl = document.createElement('div');
        this._rootEl.id = 'o365-notes-app';
        this._rootEl.style.position = 'fixed';
        this._rootEl.style.bottom = '1px';
        this._rootEl.style.top = '50px';
        this._rootEl.style.right = '1px';
        this._rootEl.style.width = '400px';
        this._rootEl.classList.add('border-start')
        const iframe = document.createElement('iframe');
        const theme = document.querySelector('html')?.getAttribute('data-bs-theme');
        iframe.src = theme === 'dark' ? 'https://omega.omega365.com/nt/mynotes?theme=dark' : 'https://omega.omega365.com/nt/mynotes';
        iframe.classList.add('w-100', 'h-100');

        iframe.onload = () => {
            if (this._messageChannel) {
                this._messageChannel.close();
            }

            this._messageChannel = new MasterMessageChannel({
                id: 'o365-notes-app-channel',
                connectDelay: 500,
                targetOrigin: 'https://omega.omega365.com',
                functions: {
                    'close': () => {
                        this.close();
                    },
                }
            });

            this._messageChannel?.connect(iframe).then(() => {
                if (theme) {
                    this._messageChannel?.execute('setTheme', theme)
                }
            });
        };

        this._rootEl.appendChild(iframe);
        document.body.appendChild(this._rootEl);
    }
}
let disabledInPage = false;

/** Toggle My Notes app */
export default function toggleNotesSidepanel() {
    if (disabledInPage) { return; }
    const isOpen = NotesAppIframe.isOpen;
    const instance = NotesAppIframe.getInstance();
    if (isOpen) {
        instance.close();
    }
    // if (disabledInPage || NotesAppIframe.isOpen) { return; }
}

export function disable() {
    disabledInPage = true;
}